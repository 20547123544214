// import * as Sentry from "@sentry/browser";
import { useCallback, useRef, useState } from "react";

export type Status<T = any> = {
  running: boolean;
  complete?: boolean;
  error?: Error;
  callbackResult?: T;
};

export const useTransaction = <Args extends any[], Result extends any>(
  callback: (...args: Args) => Result
) => {
  const [status, setStatus] = useState<Status<Awaited<Result>>>({
    running: false,
    complete: false,
  });
  const unmount = useRef(false);
  // TODO: fix
  // useEffect(() => () => (unmount.current = true), []);
  const handler = useCallback(
    async (...args: Args) => {
      setStatus({ running: true, complete: false });
      try {
        const callbackResult = await callback(...args);
        !unmount.current &&
          setStatus({
            running: false,
            complete: true,
            error: undefined,
            callbackResult: callbackResult,
          });
      } catch (error: any) {
        // Sentry.captureException(error);
        !unmount.current &&
          setStatus({ running: false, complete: false, error });
      }
    },
    [callback]
  );

  const reset = useCallback(() => {
    !unmount.current && setStatus({ running: false, complete: false });
  }, [setStatus]);

  return [status, handler, reset] as const;
};
