import { Colors } from "components/colors";
import { Font } from "components/font";
import { Button } from "components/forms/Button";
import { ActivityButton } from "components/forms/Button/ActivityButton";
import { CheckBoxLabel } from "components/forms/CheckBox/CheckBoxLabel";
import { InputField } from "components/forms/InputField";
import { TextField } from "components/forms/TextField";
import { PasswordField } from "components/forms/TextField/PasswordField";
import { EnvironmentDanger } from "components/layouts/EnvironmentDanger";
import { Stack } from "components/layouts/Stack";
import { BrandLogo } from "components/widgets/BrandLogo";
import { Card } from "components/widgets/Card";
import { CardSection } from "components/widgets/Card/CardSection";
import { H2 } from "components/widgets/H";
import { getAdminAccount, postAdminDeliveryClientsSessions } from "libs/api";
import { APIError } from "libs/api/client";
import { useTransaction } from "libs/hooks/useTransaction";
import { radius, spacing } from "libs/styles/variables";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { Fragment, memo, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

export default function Root() {
  return <Component />;
}

const defaultValues = {
  email: "",
  password: "",
  remember_me: "",
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  try {
    await getAdminAccount({
      cookie: ctx.req.headers.cookie,
    });

    return {
      redirect: {
        permanent: false,
        destination: "/delivery_requests",
      },
    };
  } catch (e) {
    return {
      props: {},
    };
  }
};

const Component = memo(function Component() {
  const router = useRouter();
  const form = useForm({ shouldUnregister: false, defaultValues });
  const [error, setError] = useState<
    null | "invalid" | "locked" | "network" | "invitation_inactive"
  >(null);
  const { errors, register, watch, handleSubmit } = form;
  const handleRegister = useCallback(() => {
    router.push("/delivery_clients/new");
  }, [router]);
  const formData = watch();
  const formFullFilled =
    formData.email.length > 0 && formData.password.length > 0;
  const [status, handleSubmitImpl] = useTransaction(
    async (props: typeof defaultValues) => {
      setError(null);
      try {
        await postAdminDeliveryClientsSessions({
          body: {
            sessions: {
              email: props.email,
              password: props.password,
              remember_me: props.remember_me === "1",
            },
          },
        });
      } catch (e) {
        if (e instanceof APIError) {
          if (e.type === "ApiError::DeliveryClient::AccountLocked") {
            router.push("/account_locked");
          } else if (e.status === 401) {
            setError("invalid");
          } else {
            setError("network");
          }
        }

        return;
      }

      router.push("/delivery_requests");

      await new Promise((_) => {});
    }
  );

  return (
    <Fragment>
      <EnvironmentDanger />
      <Base>
        <BrandLogo height={225} width={213} />
        <Stack spacing="xxl">
          <form onSubmit={handleSubmit(handleSubmitImpl)}>
            <Card>
              <CardSection>
                <Stack spacing="xl">
                  <H2>ログイン</H2>
                  {error === "invalid" ? (
                    <Error>メールアドレスかパスワードに誤りがあります</Error>
                  ) : error === "network" ? (
                    <Error>通信エラーが発生しました</Error>
                  ) : error === "invitation_inactive" ? (
                    <Error>登録済みなため、ログインをお願いします</Error>
                  ) : null}
                  <Fragment>
                    <InputField
                      error={errors.email}
                      size="xs"
                      title="メールアドレス"
                    >
                      <TextField
                        ref={register({ required: true })}
                        block
                        error={errors.email}
                        name="email"
                        placeholder="crewexp@mail.com"
                        type="email"
                      />
                    </InputField>
                    <InputField
                      error={errors.password}
                      rightItem={
                        <PasswordForgetLink>
                          <a
                            href="https://api.crewexp.com/contact-form/client"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            パスワードを忘れた場合
                          </a>
                        </PasswordForgetLink>
                      }
                      size="xs"
                      title="パスワード"
                    >
                      <PasswordField
                        ref={register({ required: true })}
                        block
                        error={errors.password}
                        name="password"
                        placeholder="・・・・・・・・"
                      />
                    </InputField>
                  </Fragment>
                  <CheckBoxLabel
                    ref={register({})}
                    name="remember_me"
                    value="1"
                  >
                    ログイン状態を保持
                  </CheckBoxLabel>
                  <ActivityButton
                    block={true}
                    disabled={!formFullFilled}
                    size="md"
                    theme="primary"
                    type="submit"
                    working={status.running}
                  >
                    OK
                  </ActivityButton>
                </Stack>
              </CardSection>
            </Card>
          </form>
          <Card>
            <CardSection>
              <Stack spacing="xl">
                <H2>まだアカウントを持っていない方</H2>
                <Button block onClick={handleRegister}>
                  新規登録画面へ
                </Button>
              </Stack>
            </CardSection>
          </Card>
        </Stack>
      </Base>
    </Fragment>
  );
});

const Base = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 344px;
`;

const Error = styled.p`
  background: ${Colors.alertLight2};
  border: 1px solid ${Colors.alert};
  border-radius: ${radius.sm};
  color: ${Colors.alert};
  font-size: ${Font.fontSizeXs};
  margin: 0;
  padding: ${spacing.sm} ${spacing.md};
  text-align: center;
`;

const PasswordForgetLink = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    color: ${Colors.primary};
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;

    &:link:hover:active {
      color: ${Colors.primary};
    }

    &:visited:hover:active {
      color: ${Colors.primary};
    }
  }
`;
